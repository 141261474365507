@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

    .resume-heading {
      @apply w-full h-2/4 mb-4 m-auto border-2 border-gray-400 bg-white rounded-lg p-4 flex-col justify-between leading-normal
    }
    
    .resume-item {
      @apply flex flex-col justify-center h-1/3 m-4 relative
    }

    .header-section {
      @apply inline float-right px-4 inline float-right px-4 hover:font-bold
    }
    .header-section > .active {
      @apply border-b border-white
    } 
  }